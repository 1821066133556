<template>
    <div> 
        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_NTcouncil">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">                        
                        <tr v-for="(item, key) in data.data.data" :key="key" >   
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage('ntcouncil', item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >
                                <!-- <td v-if="column.Status && column.code == 'ntcouncil_042'" :code="column.code">
                                    <span v-html="icon_link(item.drug_id,'ntcouncil')"></span>
                                </td> -->
                                <td v-if="column.Status && column.code == 'ntcouncil_001'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span> 
                                </td>
                                <td v-else-if="column.Status && column.code == 'ntcouncil_041'" :code="column.code">
                                    {{item.drug_id}}
                                </td>  
                                <td v-else-if="column.Status && column.code == 'ntcouncil_003'" :code="column.code">
                                    <span v-html="check_empty(array(item.atc))"></span>
                                </td>   
                                <td v-else-if="column.Status && column.code == 'ntcouncil_004'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>   
                                <td v-else-if="column.Status && column.code == 'ntcouncil_005'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_006'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>     
                                <td v-else-if="column.Status && column.code == 'ntcouncil_007'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_008'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>    
                                <td v-else-if="column.Status && column.code == 'ntcouncil_009'" :code="column.code">
                                    {{check_empty_with_locale(item.mk_orphelin)}}
                                </td>   
                                <td v-else-if="column.Status && column.code == 'ntcouncil_002'" :code="column.code">
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>                      
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_010'" :code="column.code">
                                    <span v-if="$i18n.locale !== 'fr'" v-html="check_empty(array(item.classification_en))"  ></span>
                                    <span v-else v-html="check_empty(array(item.classification_fr))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ntcouncil_011'" :code="column.code">
                                    <Readmore :longText="item.indication_en"/>
                                </td>  
                                <td v-else-if="column.Status && column.code == 'ntcouncil_012'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.recommendations"/>
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_013'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.drug_description"/>
                                </td>   
                                <td v-else-if="column.Status && column.code == 'ntcouncil_014'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.assessment_role_therapy"/>
                                </td>                                                                    
                                <td v-else-if="column.Status && column.code == 'ntcouncil_016'" :code="column.code">
                                    {{check_empty(item.ntcouncil.summary_severity_condition)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ntcouncil_017'" :code="column.code">                                   
                                    {{check_empty(item.ntcouncil.summary_effect_treatment_drug)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ntcouncil_018'" :code="column.code">                                   
                                    {{check_empty(item.ntcouncil.summary_reliability_scientific_documentation)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ntcouncil_019'" :code="column.code">                               
                                    {{check_empty(item.ntcouncil.summary_rarity_condition)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ntcouncil_020'" :code="column.code">                                    
                                    {{check_empty(item.ntcouncil.summary_reliability_health_economic_assessment)}}
                                </td>
                                
                                <td v-else-if="column.Status && column.code == 'ntcouncil_021'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.severity_condition"/>  
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_022'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.treatment_effectiveness"/>   
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_023'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.scientific_documentation_reliability"/>   
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_024'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.rarity_condition"/>   
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_025'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.treatment_cost_effectiveness"/>   
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_026'" :code="column.code">
                                    <Readmore :longText="item.ntcouncil.reliability_health_economic_assessment"/>    
                                </td> 
                                <td v-else-if="column.Status && column.code == 'ntcouncil_027'" :code="column.code">
                                    <span v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                </td>
                                <template  v-else-if="column.Status && column.code == 'ntcouncil_029'" >                             
                                    <td  :key="column.code+'_031'" code="ntcouncil_031">
                                        <Readmore :longText="item.target_population"/> 
                                    </td>      
                                    <td :key="column.code+'_032'" code="ntcouncil_032">
                                        <Readmore :longText="item.prescription_and_delivery_conditions"/> 
                                    </td>      
                                    <td :key="column.code+'_033'" code="ntcouncil_033">
                                        <Readmore :longText="item.access_scheme"/> 
                                    </td>      
                                    <td :key="column.code+'_034'" code="ntcouncil_034">
                                        <Readmore :longText="item.outcome_level"/> 
                                    </td>      
                                </template>
                                <td v-else-if="column.Status && column.code == 'ntcouncil_035'" :code="column.code">     
                                    <Readmore :longText="item['rationale_and_comments_html_en']"/>         
                                </td>   
                                
                                <td v-else-if="column.Status && column.code == 'ntcouncil_038'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>                                      
                                <td v-else-if="column.Status && column.code == 'ntcouncil_039'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'ntcouncil_040'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>

                            </template>
                        </tr>
                    </template>
                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>
                </tbody>
            </table>
        </div>     
    </div>
</template>
 

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import Readmore from '@/components/elements/readmore.vue';
import { isAdmin, hta_process_status, modifyPage, ema_id, icon_link, DDMMMYY, check_empty, 
        nct_number, treatment_line, datediff, array, check_empty_with_locale } from '@/utils'
export default {
    name: "Ntcouncil",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore
    },  
    data : function() {
        return { 
            drug_id : 0   // for Essais clinique popup
        }
    },
    computed: {                
        data(){
            console.log('This is data >>>>>>>>>>>>>>');
            console.log(this.$store.getters['result/data']);
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){
                this.$store.dispatch("result/select_export", val)
                 },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        hta_process_status,
        isAdmin,
        modifyPage,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        check_empty_with_locale
    },
}
</script>
<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>

 